'use client';
import { useQueryString } from '@hooks/client';
import { FooterLink } from '@widgets/desktop/footer/ui/utils';
import styles from '@widgets/mobile/footer/ui/footer.module.scss';
import { motion } from 'framer-motion';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { Filter } from '@/shared/types/common';
import { Accordion, Illustration } from '@/shared/ui';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { AllIcons } from '@/shared/ui/illustration/illustration';
import { DropListSortMobile } from '@/shared/ui/mobile/drop-list-sort';

type FooterWidgetMobileLinksListProps = {
  trigger: Filter;
  links: FooterLink[];
};

// eslint-disable-next-line react/display-name
export const FooterWidgetMobileLinksList = ({
  trigger,
  links,
}: FooterWidgetMobileLinksListProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openAccordion, setOpenAccordion] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { createQueryString } = useQueryString();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();

  const handlePress = (href: string, isCategory?: boolean) => () => {
    if (isCategory) {
      router.push(
        createQueryString({
          pathname: '/shop',
          name: 'category',
          value: href.toLowerCase(),
          asMultipleParams: false,
        }),
      );
    } else {
      router.push(href);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setOpenAccordion(false);
  }, [pathname]);

  return (
    <Accordion
      as={motion.section}
      toggle={() => {
        setOpenAccordion(prev => !prev);
      }}
      isOpened={openAccordion}
      triggerSlot={
        <DropListSortMobile
          variant={'secondary'}
          isOpened={openAccordion}
          selectedItem={trigger}
        />
      }
      listSlot={
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.35,
              staggerChildren: 0.3,
              delayChildren: 0.3,
            },
            height: 'max-content',
          }}
          exit={{
            opacity: 0,
            height: 0,
            transition: {
              staggerChildren: 0.07,
              staggerDirection: -1,
              duration: 0.35,
            },
          }}
          className={styles['links-list']}
        >
          {Array.isArray(links) &&
            links.length > 0 &&
            links.map((link, idx) => {
              return (
                <motion.div key={`footer-link-${idx}-icon-${link.icon}`}>
                  <DropListItem
                    className={styles['link-container']}
                    onPress={handlePress(link.href, link.isCategory)}
                    title={link.text}
                    iconLeft={
                      <Illustration
                        size={22}
                        style={{ color: link.color }}
                        name={link.icon as keyof AllIcons}
                        spriteName={'icons'}
                      />
                    }
                  />
                </motion.div>
              );
            })}
        </motion.div>
      }
    />
  );
};
